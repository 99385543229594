import React from 'react';
import { Package as PackageIcon, Truck } from 'lucide-react';
import {FaBoxOpen, FaClipboard, FaShippingFast} from "react-icons/fa";
import {AiOutlineCheckCircle, AiOutlineSetting} from "react-icons/ai";

const PackageStatusSVG = ({ status }) => {
    const steps = ['Processing', 'In Transit', 'Out for Delivery', 'Delivered'];
    const currentStep = steps.indexOf(status) !== -1 ? steps.indexOf(status) : 0;

    // Map step to corresponding icon component
    const icons = [AiOutlineSetting, FaShippingFast, FaBoxOpen, AiOutlineCheckCircle];

    return (
        <svg width="100%" height="160" viewBox="0 0 400 160" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <filter id="shadow" x="-10%" y="-10%" width="130%" height="130%">
                    <feDropShadow dx="0" dy="2" stdDeviation="2" floodColor="#000" floodOpacity="0.1" />
                </filter>
            </defs>
            {steps.map((step, index) => {
                const x = 100 * index + 50; // Ensure centered distribution
                const isActive = index <= currentStep;
                const color = isActive ? "#ff5923" : "#E5E7EB";
                const textColor = isActive ? "#1F2937" : "#9CA3AF";
                const IconComponent = icons[index];

                return (
                    <g key={step}>
                        {index < steps.length - 1 && (
                            <line
                                x1={x + 20}
                                y1="60"
                                x2={x + 100} // Ensure lines connect correctly
                                y2="60"
                                stroke={index < currentStep ? "#ff5923" : "#E5E7EB"}
                                strokeWidth="4"
                            />
                        )}
                        <circle cx={x} cy="60" r="20" fill={color} filter={isActive ? "url(#shadow)" : "none"} />
                        <foreignObject x={x - 10} y="50" width="20" height="20" style={{ overflow: 'visible' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <IconComponent
                                    size={20} // Increased size
                                    color="#FFFFFF"
                                    style={{ stroke: '#D1D5DB', strokeWidth: '1' }}
                                />
                            </div>
                        </foreignObject>
                        <text x={x} y="120" textAnchor="middle" fill={textColor} fontSize="12" fontWeight="bold">
                            {step}
                        </text>
                    </g>
                );
            })}
        </svg>
    );
};

export const Package = ({ packageData }) => {
    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            color: '#1F2937',
            padding: '20px',
        },
        section: {
            marginBottom: '20px'
        },
        trackingNumber: {
            backgroundColor: '#F3F4F6',
            padding: '12px',
            borderRadius: '8px',
            marginBottom: '20px',
        },
        trackingNumberText: {
            fontSize: '16px',
            fontWeight: 'bold',
            margin: '8px 0 0 0',
        },
        sectionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#4B5563',
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            marginRight: '8px',
        },
        infoGrid: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
        },
        infoItem: {
            flex: 1,
        },
        infoTitle: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#4B5563',
            marginBottom: '4px',
        },
        infoText: {
            fontSize: '14px',
            color: '#6B7280',
        },
        eventsList: {
            borderLeft: '2px solid',
            borderColor: '#e5e7eb',
            paddingLeft: '20px',
        },
        event: {
            position: 'relative',
            marginBottom: '16px',
        },
        eventDot: {
            position: 'absolute',
            left: '-28px',
            top: '4px',
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            backgroundColor: '#ff5923',
        },
        eventTime: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#4B5563',
            marginBottom: '4px',
        },
        eventDescription: {
            fontSize: '16px',
            marginBottom: '4px',
        },
        eventLocation: {
            fontSize: '14px',
            color: '#6B7280',
        },
        copyIcon: {
            marginLeft: '12px',
            cursor: 'pointer',
            color: '#ff5923',
            marginTop: '6px',
        },
        inlineContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then();
    };

    return (
        <div style={styles.container}>
            <div style={styles.trackingNumber}>
                <div style={styles.sectionTitle}>
                    <PackageIcon size={20} style={styles.icon}/>
                    Tracking Number
                </div>
                <div style={styles.inlineContainer}>
                    <div style={styles.trackingNumberText}>{packageData.trackingNumber}</div>
                    <FaClipboard
                        size={18}
                        style={styles.copyIcon}
                        onClick={() => copyToClipboard(packageData.trackingNumber)}
                        title="Copy to clipboard"
                    />
                </div>

            </div>

            <div style={styles.section}>
                <div style={styles.sectionTitle}>Status</div>
                <PackageStatusSVG status={packageData.status}/>
            </div>

            <div style={styles.infoGrid}>
                <div style={styles.infoItem}>
                    <div style={styles.infoTitle}>Last Update</div>
                    <p style={styles.infoText}>{packageData.lastUpdate}</p>
                </div>
                <div style={styles.infoItem}>
                    <div style={styles.infoTitle}>Estimated Delivery</div>
                    <p style={styles.infoText}>
                        None
                    </p>
                </div>
            </div>

            <div style={styles.section}>
                <div style={styles.sectionTitle}>
                    <Truck size={20} style={styles.icon} />
                    Tracking Events
                </div>
                {packageData.events && packageData.events.length > 0 ? (
                    <div style={styles.eventsList}>
                        {packageData.events.map((event, index) => (
                            <div key={index} style={styles.event}>
                                <div style={{...styles.eventDot, backgroundColor: index === 0 ? '#ff5923' : '#e5e7eb'}}></div>
                                <p style={styles.eventTime}>{event.readableTime}</p>
                                <p style={styles.eventDescription}>{event.description}</p>
                                <p style={styles.eventLocation}>{event.office}</p>
                                <p style={styles.eventLocation}>{event.destination}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p style={styles.infoText}>No tracking information available for this package.</p>
                )}
            </div>
        </div>
    );
};

