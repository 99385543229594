// src/components/ui/dialog.jsx
import React from 'react';

export const Dialog = ({ children, open, onOpenChange }) => {
    if (!open) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        }}>
            <div style={{
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '20px',
                maxWidth: '500px',
                width: '100%',
                maxHeight: '80vh',
                overflowY: 'auto',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}>
                {children}
            </div>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                }}
                onClick={() => onOpenChange(false)}
            />
        </div>
    );
};

export const DialogContent = ({ children, className = '', style = {}, ...props }) => (
    <div className={className} style={style} {...props}>
        {children}
    </div>
);

export const DialogHeader = ({ children, className = '', style = {}, ...props }) => (
    <div className={className} style={{ marginBottom: '16px', ...style }} {...props}>
        {children}
    </div>
);

export const DialogTitle = ({ children, className = '', style = {}, ...props }) => (
    <h3 className={className} style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '8px', ...style }} {...props}>
        {children}
    </h3>
);

export const DialogFooter = ({ children, className = '', style = {}, ...props }) => (
    <div className={className} style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end', ...style }} {...props}>
        {children}
    </div>
);
