// src/components/ui/button.jsx
import React from 'react';

export const Button = ({ children, className = '', style = {}, ...props }) => (
    <button
        style={{
            padding: '8px 16px',
            borderRadius: '4px',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#ff5a21',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            ...style
        }}
        className={className}
        {...props}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#fc4867'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#ff5a21'}
    >
        {children}
    </button>
);
