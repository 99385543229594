// src/components/ui/alert.jsx
import React from 'react';

export const Alert = ({ children, variant = 'info', className = '', style = {}, ...props }) => {
    const variantStyles = {
        info: { backgroundColor: '#EFF6FF', color: '#1E40AF' },
        warning: { backgroundColor: '#FFFBEB', color: '#92400E' },
        destructive: { backgroundColor: '#FEE2E2', color: '#B91C1C' },
    };

    return (
        <div
            style={{
                padding: '16px',
                borderRadius: '4px',
                ...variantStyles[variant],
                ...style
            }}
            className={className}
            role="alert"
            {...props}
        >
            {children}
        </div>
    );
};

export const AlertDescription = ({ children, className = '', style = {}, ...props }) => (
    <p
        style={{
            fontSize: '14px',
            ...style
        }}
        className={className}
        {...props}
    >
        {children}
    </p>
);
