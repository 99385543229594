// src/components/ui/input.jsx
import React from 'react';

export const Input = ({ className = '', style = {}, ...props }) => (
    <input
        style={{
            padding: '8px 12px',
            border: '1px solid #ff5a21',
            borderRadius: '4px',
            fontSize: '16px',
            outline: 'none',
            width: '100%',
            transition: 'border-color 0.3s, box-shadow 0.3s',
            ...style
        }}
        className={className}
        {...props}
        onFocus={(e) => {
            e.target.style.borderColor = '#fc9977';
            e.target.style.boxShadow = '0 0 0 1px #fc5a21';
        }}
        onBlur={(e) => {
            e.target.style.borderColor = '#ff5a21';
            e.target.style.boxShadow = 'none';
        }}
    />
);
