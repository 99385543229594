import React, { useState, useCallback } from 'react';
import { Cloud, RefreshCw, X } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from './components/ui/dialog';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { Alert, AlertDescription } from './components/ui/alert';
import useLocalStorageState from 'use-local-storage-state';
import {Package} from "./components/ui/package";

const API_BASE_URL = process.env.BACKEND_API_BASE_URL;
const isDemoMode = process.env.REACT_APP_DEMO_MODE === 'true';

const generateMockData = (trackingCode) => {
  const statuses = ['Processing', 'In Transit', 'Out for Delivery', 'Delivered'];
  const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
  const eventCount = Math.floor(Math.random() * 5) + 1;

  const events = Array.from({ length: eventCount }, (_, index) => ({
    timestamp: new Date(Date.now() - (index * 24 * 60 * 60 * 1000)).toISOString(),
    readableTime: new Date(Date.now() - (index * 24 * 60 * 60 * 1000)).toLocaleString(),
    description: index === 0 ? randomStatus : `Package ${statuses[Math.floor(Math.random() * statuses.length)].toLowerCase()}`,
    office: `Mock Office ${index + 1}`,
    destination: index === 0 ? 'Final Destination' : 'In Transit',
  }));

  return {
    status: "success",
    trackingCode: trackingCode,
    totalEvents: events.length,
    events: events,
    pkgStatus: statuses[Math.floor(Math.random() * statuses.length)],
  };
};

// localStorage.clear();
async function getTrackingEvents(trackingCode) {
  if (isDemoMode || true) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(generateMockData(trackingCode));
      }, 1000);
    });
  }
  try {
    const response = await fetch(`${API_BASE_URL}${trackingCode}`, {
      headers: {
        "accept": "application/json",
        "accept-language": "en-US,en;q=0.9",
      },
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "success" && Array.isArray(data.events)) {
      return {
        status: "success",
        trackingCode: data.trackingCode,
        totalEvents: data.totalEvents,
        events: data.events,
        pkgStatus: data.pkgStatus,
      };
    } else {
      return {
        status: "no_data",
        message: "No tracking information available for this package.",
        errorMessage: 'Tracking info not available.',
        trackingCode: trackingCode
      };
    }
  } catch (error) {
    console.error('Error in getTrackingEvents:', error);
    return {
      status: "error",
      message: "Failed to fetch data (server error!)",
      description: error.message,
      trackingCode: trackingCode
    };
  }
}

const App = () => {
  const [packages, setPackages] = useLocalStorageState('packages', { defaultValue: [] });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [newTrackingNumber, setNewTrackingNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPackageDetails = useCallback(async (trackingCode) => {
    setLoading(true);
    setError(null);
    try {
      const result = await getTrackingEvents(trackingCode);
      console.log(result);
      setPackages(prevPackages =>
          prevPackages.map(pkg =>
              pkg.trackingNumber === trackingCode
                  ? {
                    ...pkg,
                    events: result.events || [],
                    lastUpdate: new Date().toLocaleString(),
                    status: result.status === "success" && result.events.length > 0 ? result.pkgStatus : "Unknown",
                    error: result.status !== "success" ? result.errorMessage : null
                  }
                  : pkg
          )
      );
      if (result.status !== "success") {
        setError(`${result.message} (${trackingCode})`);
      }
    } catch (err) {
      console.error(`Failed to fetch details for ${trackingCode}:`, err);
      setError(`Failed to fetch details for ${trackingCode}: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [setPackages]);

  const handleAddPackage = async () => {
    if (newTrackingNumber.trim()) {
      const newPackage = {
        id: `PKG${Date.now()}`,
        trackingNumber: newTrackingNumber.trim(),
        status: 'Processing',
        lastUpdate: new Date().toLocaleString(),
        events: []
      };
      setPackages(prevPackages => [...prevPackages, newPackage]);
      setNewTrackingNumber('');
      setIsAddModalOpen(false);
      await fetchPackageDetails(newPackage.trackingNumber);
    }
  };

  const handleRefresh = async () => {
    setError(null);
    for (const pkg of packages) {
      await fetchPackageDetails(pkg.trackingNumber);
    }
  };

  const handleDelete = (id) => {
    setPackages(prevPackages => prevPackages.filter(pkg => pkg.id !== id));
  };

  const openDetailsModal = (pkg) => {
    setSelectedPackage(pkg);
    setIsDetailsModalOpen(true);
  };

  return (
      <div className="min-h-screen flex flex-col">
        <header className="bg-gradient-to-r from-superOrange to-superRed p-4 text-white">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Cloud className="h-6 w-6" />
              <h1 className="text-xl font-bold">Package Tracker</h1>
            </div>
            <nav className="space-x-6">
              <button className="font-semibold">Packages</button>
              <button className="opacity-80 hover:opacity-100">Settings</button>
            </nav>
          </div>
        </header>

        <main className="flex-grow bg-white relative">
          <div className="gradient-background absolute top-0 left-0 right-0 h-1/3"></div>
          <div className="max-w-7xl mx-auto p-6 relative z-10">
            {error && (
                <Alert variant="destructive" className="mb-4">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-4xl font-bold text-white drop-shadow-xl">Tracked Packages</h2>
              <button
                  onClick={handleRefresh}
                  style={{
                    padding: '8px',
                    borderRadius: '9999px',
                    color: "#FFF",
                    background: "#578ef3",
                  }}
                  disabled={loading}
              >
                <RefreshCw className={`h-5 w-5 ${loading ? 'animate-spin' : ''}`} />
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              {packages.length === 0 ? (
                  <p className="p-4 text-gray-500">No packages tracked yet. Add a package to get started!</p>
              ) : (
                  packages.map((pkg) => (
                      <div key={pkg.id} className="border-b border-gray-200 last:border-b-0">
                        <div className="flex items-center justify-between p-4">
                          <div className="flex-grow">
                            <h3 className="font-bold text-lg">{pkg.trackingNumber}</h3>
                            <p className="text-sm text-gray-600">Last Update: {pkg.lastUpdate}</p>
                            {pkg.error ? (
                                <span className="px-2 py-1 rounded-full text-sm bg-red-200 text-red-800 flex items-center">
                          Error: {pkg.error}
                        </span>
                            ) : (
                                <span className={`px-2 py-1 rounded-full text-sm ${
                                    pkg.status.toLowerCase().includes('delivered') ? 'bg-green-200 text-green-800' :
                                        pkg.status.toLowerCase().includes('transit') ? 'bg-blue-200 text-blue-800' :
                                            'bg-yellow-200 text-yellow-800'
                                }`}>
                          {pkg.status}
                        </span>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            <Button
                                onClick={() => openDetailsModal(pkg)}
                                style={{
                                  borderRadius: '14px'
                                }}
                            >
                              Show Details
                            </Button>
                            <button
                                onClick={() => handleDelete(pkg.id)}
                                className="text-red-400 hover:text-red-500"
                            >
                              <X className="h-6 w-6" />
                            </button>
                          </div>
                        </div>
                      </div>
                  ))
              )}
            </div>
            <Button
                onClick={() => setIsAddModalOpen(true)}
                style={{
                  marginTop: '16px',
                  padding: '12px 24px',
                  borderRadius: '14px'
                }}
            >
              + Add Package
            </Button>
          </div>
        </main>

        {/* Add Package Modal */}
        <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Package</DialogTitle>
            </DialogHeader>
            <Input
                value={newTrackingNumber}
                onChange={(e) => setNewTrackingNumber(e.target.value)}
                placeholder="Enter tracking number"
            />
            <DialogFooter>
              <Button
                  onClick={handleAddPackage}
                  style={{
                    width: '50%',
                    padding: '12px',
                    borderRadius: '14px',
                  }}
                  disabled={loading}
              >
                {loading ? 'Adding...' : 'Add Package'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Package Details Modal */}
        <Dialog open={isDetailsModalOpen} onOpenChange={setIsDetailsModalOpen}>
          <DialogContent style={{ maxWidth: '600px' }}>
            <DialogHeader>
              <DialogTitle style={{ fontSize: '24px', color: '#1F2937' }}>Package Details</DialogTitle>
            </DialogHeader>
            {selectedPackage && (
                <Package packageData={selectedPackage} />
            )}
            <DialogFooter>
              <Button
                  onClick={() => setIsDetailsModalOpen(false)}
                  style={{
                    width: '32%',
                    padding: '12px',
                    borderRadius: '14px'
                  }}
              >
                Close
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <style jsx>{`
        .gradient-background {
          background: linear-gradient(0deg, rgba(255,90,31,0) 0%, rgba(255,90,31,0.1) 50%, rgba(255,90,31,0.2) 100%);
        }
      `}</style>
      </div>
  );
};

export default App;
